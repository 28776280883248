<template lang="html">
  <div>
    <Header :animated="false" />
    <section id="legal" class="feymargins animated bounceInDown">
        <div class="container">

            <div class="section-header">
                <h3 class="section-title">{{ $trs('legal.title') }}</h3>
                <span class="section-divider"></span>
                <p class="section-description">Texte ici...</p>
            </div>

            <div>
                Section ici...
            </div>

        </div>
    </section>
    <Footer/>
  </div>
</template>



<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Legal',
  components: {
    Header,
    Footer
  },
}
</script>




<style lang="css" scoped>

</style>
